import { equal } from "@avvoka/shared"
import type { V } from "@component-utils/types"

export const findSelectOption = <T>(value: NoInfer<T> | undefined | null, field: keyof V.Select.Item<T>, options: V.Select.Option<T>[]): V.Select.Item<T> | undefined => {
  if (typeof value === 'undefined' || value === null) return undefined
  else return options.reduce<V.Select.Item<T> | undefined>((memo, option) => {
    if (memo) return memo
    else if ('type' in option) {
      if (option.type === 'group') return findSelectOption(value, field, option.options)
    } else {
      if (equal(option[field], value)) return option
    }
  }, undefined)
}

export const getSelectOptionsFromValues = <T>(values: NoInfer<T>[], options: V.Select.Option<T>[]): V.Select.Item<T>[] => {
  return options.reduce<V.Select.Item<T>[]>((memo, option) => {
    if ('value' in option) {
      if (values.includes(option.value)) memo.push(option)
    } else if (option.type === 'group') {
      memo.push(...getSelectOptionsFromValues(values, option.options))
    }

    return memo
  }, [])
}

export type SharedProps<T> = {
  options: V.Select.Option<T>[]
  id?: string
  label?: string
  labelTooltip?: string
  description?: string
  placeholder?: string
  disabled?: boolean
  readonly?: boolean
  search?: boolean
  searchPlaceholder?: string
  open?: boolean
  allowEmpty?: boolean
}
